<span ngbDropdown class="dropdown" (openChange)="openChange($event)" autoClose="outside">
    <a ngbDropdownToggle href="javascript:void(0);">update {{ entity }}</a>
    <ul ngbDropdownMenu class="dropdown-menu">
        <div class="search">
            <i class="fa fa-search"></i>
            <input #filter type="text" class="form-control" [(ngModel)]="filterText" placeholder="Search" />
            <button *ngIf="canAddNewTag && filterTextCanBeAdded" class="btn btn-transparent btn-xs btn-add" (click)="addNewTag()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <div class="scrollable-menu">
            <li *ngFor="let item of filteredTags">
                <a (click)="itemSelected(item)"> <i class="fa fa-fw" [ngClass]="{ 'fa-check': item.Selected }"></i>{{ item.Item.Name }} </a>
            </li>
            <em *ngIf="!(filteredTags && filteredTags.length)" class="no-match">no {{ entity }} match search</em>
        </div>
        <li *ngIf="selectedTags.length" class="select-all-separator">
            <a class="btn btn-xs btn-default btn-flat select-all-button" (click)="removeAll()"> remove all </a>
        </li>
    </ul>
</span>
