import { Injectable } from '@angular/core';
import { Observable, forkJoin, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import * as mime from 'mime';

@Injectable({
    providedIn: 'root',
})
export class InlineContentServiceFactory {
    private readonly extractor = new DOMInlineContentExtractor(new UuidWithoutHyphenInlineIdGenerator());
    private readonly dataLoader = new FetchInlineDataLoader();

    getInstance(api: IInlineContentApi): InlineContentService {
        return new InlineContentService(api, this.extractor, this.dataLoader);
    }
}

export interface IInlineContentApi {
    uploadInlineContentFile(entityId: number, inlineId: string, file: File): Observable<void>;
    updateInlineContentSource(entityId: number): Observable<void>;
}

export interface IInlineIdGenerator {
    generateId(): string;
}

export interface IInlineDataLoader {
    getFile(dataUrl: string): Observable<Blob>;
}

export interface IInlineContentExtractor {
    extractInlineContent(html: string): IInlineExtraction;
}

export class DOMInlineContentExtractor implements IInlineContentExtractor {
    constructor(private readonly idGenerator: IInlineIdGenerator) {
    }

    extractInlineContent(html: string): IInlineExtraction {
        const inlineData: IDataUrl[] = [];
        const div = document.createElement('div');
        div.innerHTML = html;

        const images = div.getElementsByTagName('img');
        for (let i = 0; i < images.length; ++i) {
            const img = images[i];
            if (img.src?.match(/^data:/i)) {
                const inlineId = this.idGenerator.generateId();
                inlineData.push({
                    dataUrl: img.src,
                    inlineId: inlineId,
                });
                img.src = '';
                img.alt = `Pending (${inlineId})`;
                img.setAttribute('data-inline-id', inlineId);
            }
        }

        return {
            inlineData: inlineData,
            wysiwygHtml: div.innerHTML,
        };
    }
}

export class UuidWithoutHyphenInlineIdGenerator implements IInlineIdGenerator {
    generateId(): string {
        return UUID.UUID().replace(/-/g, '');
    }
}

export class FetchInlineDataLoader implements IInlineDataLoader {
    getFile(dataUrl: string): Observable<Blob> {
        return from(this.fetchData(dataUrl));
    }

    async fetchData(dataUrl: string): Promise<Blob> {
        const response = await fetch(dataUrl);
        const blob = await response.blob();
        return blob;
    }
}

export class InlineContentService {
    constructor(
        private readonly api: IInlineContentApi,
        private readonly extractor: IInlineContentExtractor,
        private readonly dataLoader: IInlineDataLoader,
    ) {
    }

    setInlineContent(wysiwygHtml: string, createOrUpdateEntity: (wysiwygHtml: string) => Observable<number>): Observable<number> {
        const extraction = this.extractor.extractInlineContent(wysiwygHtml);
        if (!extraction.inlineData || !extraction.inlineData.length) {
            return createOrUpdateEntity(wysiwygHtml);
        }
        return forkJoin({
            entityId: createOrUpdateEntity(extraction.wysiwygHtml),
            files: forkJoin(extraction.inlineData.map((x) => this.dataLoader.getFile(x.dataUrl).pipe(
                map((blob) => ({
                    file: new File([blob], `${x.inlineId}.${mime.getExtension(blob.type) || 'file'}`, { type: blob.type }),
                    inlineId: x.inlineId,
                })),
            ))),
        }).pipe(
            switchMap((x) => forkJoin(x.files.map((f) => this.api.uploadInlineContentFile(x.entityId, f.inlineId, f.file))).pipe(
                map(() => x.entityId),
            )),
            switchMap((entityId) => this.api.updateInlineContentSource(entityId).pipe(
                map(() => entityId),
            )),
        );
    }
}

export interface IDataUrl {
    dataUrl: string;
    inlineId: string;
}

export interface IInlineExtraction {
    wysiwygHtml: string;
    inlineData: IDataUrl[];
}
