import { NotificationPopupService } from './notification-popup.service';
import { Component, ComponentRef, ElementRef, Injector, NgZone, Renderer2, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { PopupService, positionElements } from '@mt-ng2/type-ahead-control';
import { IMenuAction, MenuActionTypes, MenuWindowComponent } from './menu-window.component';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'custom-menu',
    styleUrls: ['./menu.component.less'],
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnDestroy {
    /**
     * service used to open the window component below
     */
    popupService: PopupService<MenuWindowComponent>;
    /**
     * the component ref for the window once it is opened
     */
    windowRef: ComponentRef<MenuWindowComponent>;

    @ViewChild('menuAnchor', { static: true }) menu: ElementRef<HTMLElement>;

    canSeeUsers = false;
    canSeeAdmin = false;
    subscriptions = new Subscription();
    clickSubscriptions = new Subscription();

    menuOpen = 'fa-bars';
    /**
     * Returns true if the window is displayed
     */
    get isWindowOpen(): boolean {
        return this.windowRef != null;
    }

    constructor(
        injector: Injector,
        vcr: ViewContainerRef,
        renderer: Renderer2,
        ngZone: NgZone,
        private router: Router,
        private authService: AuthService,
        claimsService: ClaimsService,
        private notificationPopupService: NotificationPopupService,
    ) {
        this.popupService = new PopupService<MenuWindowComponent>(MenuWindowComponent, injector, vcr, renderer);
        this.canSeeUsers = claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.canSeeAdmin = claimsService.hasClaim(ClaimTypes.Admin, [ClaimValues.FullAccess]);
        this.subscriptions.add(
            ngZone.onStable.subscribe(() => {
                // this fires whenever Angular is done running a set of tasks
                // and so long as the results window is open when Angular is done running tasks
                // we want to position/reposition the results window to be attached to the input element
                if (this.isWindowOpen) {
                    positionElements(this.menu.nativeElement, this.windowRef.location.nativeElement as HTMLElement, 'bottom-right', true);
                    renderer.setStyle(this.windowRef.location.nativeElement, 'z-index', 1050); // can set this to a higher number if needed
                }
            }),
        );
        this.subscriptions.add(
            this.notificationPopupService.notificationPopupOpened.subscribe(() => {
                this.closeResultsWindow();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.clickSubscriptions.unsubscribe();
    }

    onClick(): void {
        if (this.isWindowOpen) {
            this.closeResultsWindow();
            this.menuOpen = 'fa-bars';
        } else {
            this.openResultsWindow();
            this.menuOpen = 'fa-times';
        }
    }

    openResultsWindow(): void {
        if (this.isWindowOpen) {
            return;
        }
        // open the results window
        this.windowRef = this.popupService.open();
        this.windowRef.instance.menuElementId = this.menu.nativeElement.id;
        this.windowRef.instance.canSeeUsers = this.canSeeUsers;
        this.windowRef.instance.canSeeAdmin = this.canSeeAdmin;
        // add the results window to the dom
        window.document.querySelector('body').appendChild(this.windowRef.location.nativeElement);
        // add results window click subscription
        this.clickSubscriptions.add(
            this.windowRef.instance.selection.subscribe((action: IMenuAction) => {
                if (action.type === MenuActionTypes.Route) {
                    void this.router.navigateByUrl(action.routeTo);
                } else {
                    this.logout();
                }
                this.closeResultsWindow();
            }),
        );
        // add outside click subscription
        this.clickSubscriptions.add(
            this.windowRef.instance.outsideClick.subscribe(() => {
                this.closeResultsWindow();
            }),
        );
    }

    logout(): void {
        this.authService.logout();
    }

    closeResultsWindow(): void {
        if (!this.isWindowOpen) {
            return;
        }
        this.popupService.close();
        this.clickSubscriptions.unsubscribe();
        this.clickSubscriptions = new Subscription();
        this.windowRef = null;
        this.menuOpen = 'fa-bars';
    }
}
