import { IMetaItem } from '@model/core/interfaces/base';

export enum QuestionStatuses {
    NotTriaged = 1,
    InProgress = 2,
    Answered = 3,
    Closed = 4,
    Inactive = 5,
}

export abstract class QuestionStatusesHelper {
    public static GetAsMetaItems(): IMetaItem[] {
        return [
            { Id: QuestionStatuses.NotTriaged, Name: 'Not Triaged' },
            { Id: QuestionStatuses.InProgress, Name: 'In Progress' },
            { Id: QuestionStatuses.Answered, Name: 'Answered' },
            { Id: QuestionStatuses.Closed, Name: 'Closed' },
            { Id: QuestionStatuses.Inactive, Name: 'Inactive' },
        ];
    }
}
