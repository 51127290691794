import { Component, Injector } from '@angular/core';
import { FormTextareaComponent } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-expandable-text-area',
    templateUrl: './expandable-text-area.component.html',
})
export class ExpandableTextAreaComponent extends FormTextareaComponent {
    constructor(injector: Injector) {
        super(injector);
    }
}
