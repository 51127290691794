<div *ngIf="!showUploadArea">
    <div *ngIf="attachmentsArray.length > 0">
        <div class="row">
            <div *ngFor="let attachment of attachmentsArray; let i = index">
                <div class="col-xs-4 miles-inline-list">
                    <div *ngIf="isImage(attachment.Filename); else noImage">
                        <img
                            [src]="getAttachmentApiPath(attachment) | secureBlob | async"
                            alt="{{ attachment.Filename }}"
                            class="img-responsive"
                            (click)="showModal(i)"
                        />
                        <span [ngClass]="{'primaryAttachment': primaryId === attachment.Id && !overridePrimary }" (click)="showModal(i)">{{ attachment.Filename }}</span>
                        <span *ngIf="canEdit" class="fa fa-fw fa-trash text-danger delete" (mtConfirm)="deleteAttachment(i)"></span>
                        <span class="fa fa-fw fa-star delete" *ngIf="showMarkAsPrimary" (click)="togglePrimary(attachment.Id)"></span>
                        <span class="fa fa-fw fa-download text-success" (click)="downloadAttachment(i)"></span>
                    </div>
                    <ng-template #noImage>
                        <span class="fa fa-fw {{ attachment.Filename | fileicon }}" (click)="downloadAttachment(i)"></span>
                        <span (click)="downloadAttachment(i)"> {{ attachment.Filename }}</span>
                        <span *ngIf="canEdit" class="fa fa-fw fa-trash text-danger delete" (mtConfirm)="deleteAttachment(i)"></span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="canAdd" class="attachments-add-btn">
        <button type="button" class="btn btn-flat" (click)="isEditing = true">{{ btnMessage }}</button>
    </div>
</div>
<div [hidden]="!showUploadArea">
    <mt-document
        [allowedMimeType]="allowedMimeTypes"
        [headerTitle]="headerTitle"
        (afterAddingFile)="addAttachment($event)"
        (addingFileFailed)="onWhenAddingFileFailed($event)"
    ></mt-document>
    <br />
    <button type="button" class="btn btn-xs btn-default btn-flat" (click)="cancel()">Cancel</button>
</div>
