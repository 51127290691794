import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationPopupService {
    notificationPopupOpened: Observable<void>;
    private notificationPopupOpenedSubject: Subject<void>;

    constructor() {
        this.notificationPopupOpenedSubject = new Subject<void>();
        this.notificationPopupOpened = this.notificationPopupOpenedSubject.asObservable();
    }

    openNotificationPopup(): void {
        this.notificationPopupOpenedSubject.next();
    }
}
