import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IQuestionAttachment } from '@model/core/interfaces/question-attachment';
import { AttachmentsService } from '@common/services/attachments.service';
import { ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuestionAttachmentsService extends AttachmentsService<IQuestionAttachment> {
    protected modalService = inject(ModalService);
    protected notificationsService = inject(NotificationsService);

    constructor(public http: HttpClient) {
        super('/questions', 'attachments', http);
    }

    confirmAttachment(): Observable<string | undefined> {
        return this.modalService
            .showModal({
                showCancelButton: false,
                allowOutsideClick: false,
                title: 'Attachment Confirmation',
                input: 'textarea',
                inputPlaceholder: 'Enter alt text for the screenshot',
                inputAutoFocus: true,
                inputLabel: 'Alt Text',
                text: 'If you are uploading a screenshot of an error message or a code block, please enter it as text in the description field.  Otherwise, please enter an alt text for the screenshot.',
            })
            .pipe(map((result) => (result.isConfirmed ? result.value : undefined)));
    }
}
