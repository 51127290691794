import { EntityAttachmentsComponent } from './attachments/entity-attachments.component';
import { SecureBlobPipe } from './pipes/secure.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';

import { KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';

import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule, DocumentControlModuleConfigToken } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDateModule } from '@mt-ng2/date-module';
import { MtFabSpeedDialControlModule } from '@mt-ng2/fab-speed-dial-control';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { PageTitlesModule } from '@mt-ng2/page-titles';

import { RvSearchBarComponent } from './search-bar/search-bar.component';
import { GitlabIssueListItemComponent } from './gitlab-issue-list-item/gitlab-issue-list-item.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { GlobalSearchResultsWindowComponent } from './global-search/global-search-results-window.component';
import { TagsEditComponent } from './tags/edit/tags-edit.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { GoogleDriveVideoEmbedComponent } from './google-drive-video-embed/google-drive-video-embed.component';

/**
 * ACTIVATE ROUTE PROTOTYPE EXTENSION
 */
import { ActivatedRoute } from '@angular/router';
import { getIdFromRoute } from './activated-route/activated-route.library';
import { ExpandableTextAreaComponent } from './expandable-text-area/expandable-text-area.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { dynamicFormModuleConfig } from './configs/dynamic-form.config';
import { ActionableNotificationsModule, ActionableNotificationsConfigToken } from '@mt-ng2/actionable-notifications-module';
import { NewEntityAttachmentsComponent } from './attachments/new-entity-attachments.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { SecureResourceUrlPipe } from './pipes/secureresourceurl.pipe';
import { ShowcaseVirtualTypeAheadComponent } from './showcase-virtual-type-ahead/showcase-virtual-type-ahead.component';
import { MtMentionsModule, MtMentionsUserServiceToken } from '@mt-ng2/mentions';
import { RtcModuleConfigToken, IRtcModuleConfig, RtcSignalrModule } from '@mt-ng2/rtc-module';
import { environment } from '../environments/environment';
import { UserService } from '../users/user.service';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { ListItemsModule } from './list-item.module';
import { ByUserDateModule } from './byuserdate.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from '@mt-ng2/modal-module';

declare module '@angular/router' {
    // tslint:disable-next-line:interface-name
    interface ActivatedRoute {
        getIdFromRoute: (param: string) => number;
    }
}

ActivatedRoute.prototype.getIdFromRoute = function (this: ActivatedRoute, param: string): number {
    return getIdFromRoute(this, param);
};
/**
 * END -- ACTIVATE ROUTE PROTOTYPE EXTENSION
 */

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

const rtcModuleConfig: IRtcModuleConfig = {
    connectionEndpointFullUrl: environment.connectionEndpointFullUrl,
};

@NgModule({
    declarations: [
        RvSearchBarComponent,
        GitlabIssueListItemComponent,
        GlobalSearchComponent,
        GlobalSearchResultsWindowComponent,
        TagsEditComponent,
        TruncatePipe,
        ExpandableTextAreaComponent,
        SecureBlobPipe,
        SecureResourceUrlPipe,
        NewEntityAttachmentsComponent,
        VideoUploadComponent,
        EntityAttachmentsComponent,
        ShowcaseVirtualTypeAheadComponent,
        GoogleDriveVideoEmbedComponent,
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFormModule,
        OverlayModule,
        MatTooltipModule,
        ByUserDateModule,
        ListItemsModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule,
        EntityListModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        MtDateModule,
        MtFabSpeedDialControlModule,
        TypeAheadModule,
        KeyboardShortcutModule,
        MtPhotoControlModule,
        PageTitlesModule,
        RvSearchBarComponent,
        GitlabIssueListItemComponent,
        GlobalSearchComponent,
        GlobalSearchResultsWindowComponent,
        TagsEditComponent,
        TruncatePipe,
        ExpandableTextAreaComponent,
        ActionableNotificationsModule,
        SecureBlobPipe,
        SecureResourceUrlPipe,
        NewEntityAttachmentsComponent,
        EntityAttachmentsComponent,
        VideoUploadComponent,
        ShowcaseVirtualTypeAheadComponent,
        MtMentionsModule,
        DynamicSearchFiltersModule,
        GoogleDriveVideoEmbedComponent,
        ModalModule,
    ],
    imports: [
        CommonModule,
        NgbModule,
        ListItemsModule,
        RouterModule,
        FormsModule,
        OverlayModule,
        ByUserDateModule,
        MatTooltipModule,
        DynamicFormModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        EntityComponentsAddressesModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule,
        EntityListModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        MtDateModule,
        MtFabSpeedDialControlModule,
        TypeAheadModule,
        KeyboardShortcutModule,
        MtPhotoControlModule,
        PageTitlesModule,
        RtcSignalrModule,
        TextFieldModule,
        ActionableNotificationsModule,
        MtMentionsModule,
        DynamicSearchFiltersModule,
        ModalModule,
    ],
    providers: [
        KeyboardShortcutService,
        { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
        { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
        { provide: DocumentControlModuleConfigToken, useValue: { maxFileSize: 25 * 1024 * 1024 } },
        {
            provide: ActionableNotificationsConfigToken,
            useValue: {
                allNotificationsSettings: {
                    allNotificationsRoute: '/all-notifications',
                    disableOnDismissed: true,
                    disableOnRead: false,
                },
                individualNotificationsSettings: {
                    showDismissButton: false,
                    showReadButton: true,
                },
                notificationEndpoint: environment.actionableNotificationUrl,
            },
        },
        { provide: RtcModuleConfigToken, useValue: rtcModuleConfig },
        { provide: MtMentionsUserServiceToken, useExisting: UserService },
    ],
})
export class SharedModule {}
