import { DesktopNotificationsService } from '@common/notifications/desktop-notifications.service';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';
import { environment } from './environments/environment';
import { Router, NavigationEnd, ActivatedRoute, RouterState } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { UserVisitsService } from './landing-page/services/user-visits.service';
import { IUserVisit } from '@model/core/interfaces/user-visit';

import { GoogleCalendarAuthService } from './pair-programming-sessions/services/google-calendar-auth.service';
@Component({
    selector: 'app-root',
    template: `
        <div [hidden]="appReady === null">
            <div class="wrapper" [ngClass]="pageClass">
                <div class="container-fluid" [style.min-height]="'100vh'">
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>
                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();
    pageClass = '';
    hasCheckedNotificationSubscriptions = false;
    private code = ''
    constructor(
        private authService: AuthService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private desktopNotificationsService: DesktopNotificationsService,
        private userVisitsService: UserVisitsService,
        private googleCalendarAuthService: GoogleCalendarAuthService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                if (this.appReady) {
                    this.desktopNotificationsService.initialize();
                }
            }),
        );
        this.subscriptions.add(
            this.authService.currentUser
                .asObservable()
                .pipe(filter((user) => user.Id !== 0))
                .subscribe(() => {
                    setTimeout(() => {
                        const userVisit = { UserId: this.authService.currentUser.getValue().Id };
                        this.userVisitsService
                            .create(userVisit as IUserVisit)
                            .pipe(take(1))
                            .subscribe();
                    });
                }),
        );
        this.pageClass = '';
        this.subscriptions.add(
            // pulled this example on accessing current route data from
            // https://stackoverflow.com/questions/38644314/changing-the-page-title-using-the-angular-2-new-router/38652281#38652281
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.pageClass = this.getPageClass(this.router.routerState, this.router.routerState.root);
                    this.cdRef.detectChanges();
                }
            }),
        );
        this.handleGoogleAuthRedirect();

    }

    private handleGoogleAuthRedirect(): void {
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        this.code = searchParams.get('code');
        if (this.code != null) {
            this.googleCalendarAuthService.saveToken(this.code).subscribe(() => {
                void this.router.navigate(['./pair-programming-sessions']);
            });
        } else {
            const endRoute = this.transformUrlToRoutableUrl(url);
            void this.router.navigate([endRoute.commands], {queryParams: endRoute.queryParams});
        }
    }
    private transformUrlToRoutableUrl(url: URL) : { commands: string; queryParams: object;} {
        const tryGetParams = this.getQueryParamsFromHash(url.hash);
        const routeUrl = url.hash.replace('#', '').split('?')[0];
        return {commands: routeUrl, queryParams: tryGetParams}
    }

    private getQueryParamsFromHash(url: string): object {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');
        const params = {};
        paramArr.map(param => {
            const [key, val] = param.split('=');
            if (val == null) return;
            params[key] = decodeURIComponent(val);
        })
        return params;
    }
    private getPageClass(state: RouterState, parent: ActivatedRoute): string {
        return this.getDataProperty(state, parent, 'pageClass', '', true);
    }

    private getDataProperty(state: RouterState, parent: ActivatedRoute, property: string, defaultValue: string, recursive = false): string {
        if (parent?.snapshot?.data && Object.prototype.hasOwnProperty.call(parent.snapshot.data, property)) {
            if (recursive && parent.children.length > 0 && typeof defaultValue === 'string') {
                return (parent.snapshot.data[property] as string) + this.getDataProperty(state, parent.children[0], property, defaultValue, recursive);
            } else {
                return parent.snapshot.data[property];
            }
        }

        if (state && parent) {
            return this.getDataProperty(state, parent.firstChild, property, defaultValue, recursive);
        }
        return defaultValue;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
