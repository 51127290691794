import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ByUserOnDateComponent } from "./by-user-on-date/by-user-on-date.component";
import { DateTextComponent } from "./date-text/date-text.component";
import { PluralizeIfPipe } from "./pluralize-if-pipe/pluralize-if.pipe";
import { RelativeDateTextPipe } from "./relative-date-text/relative-date-text.pipe";
import { UserNamePipe } from "./user-name-pipe/user-name.pipe";

@NgModule({
    declarations: [
        ByUserOnDateComponent,
        DateTextComponent,
        UserNamePipe,
        RelativeDateTextPipe,
        PluralizeIfPipe
    ],
    exports: [
        ByUserOnDateComponent,
        DateTextComponent,
        UserNamePipe,
        RelativeDateTextPipe,
        PluralizeIfPipe
    ],
    imports: [CommonModule],
    providers: [],
})
export class ByUserDateModule {}