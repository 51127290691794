import { environment } from '../../environments/environment';
import { catchError, concatMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';

@Injectable({
    providedIn: 'root',
})
export class DesktopNotificationsService {
    private notificationSubscribed = false;

    constructor(private swPush: SwPush, private httpClient: HttpClient) {
        this.handleNotificationClicks();
    }

    handleNotificationClicks(): void {
        this.swPush.notificationClicks.subscribe((eventData) => {
            const url = eventData.notification.data.onActionClick?.default?.url as string;
            if (!url) {
                return;
            }
            window.open(url, '_blank');
        });
    }

    createSubscription(): Observable<void> {
        return from(
            this.swPush.requestSubscription({
                serverPublicKey: environment.notificationSubscriptionPublicKey,
            }),
        ).pipe(
            concatMap((subscription) => this.httpClient.post<void>(`/notifications/subscriptions`, subscription)),
            catchError((error) => {
                console.error(`Desktop Push Registration Failed due to: ${error as string}
                If you'd like to receive notifications try removing your notification setting in your browser
                settings and allow the site again.`);
                // Printing the error above and returning null to prevent global error handler
                // This could be due to browser permissions the user isn't aware of.
                return of(null);
            }),
        );
    }

    initialize(): void {
        if (this.swPush.isEnabled) {
            this.swPush.subscription.subscribe((subscription) => {
                if (subscription === null && !this.notificationSubscribed) {
                    this.createSubscription().subscribe();
                    this.notificationSubscribed = true;
                }
            });
        }
    }
}
