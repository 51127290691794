<div *ngIf="isEditing">
    <div class="row" *ngIf="urlVideoField">
        <div class="col-md-12">
            <mt-dynamic-field [field]="urlVideoField" [form]="form"></mt-dynamic-field>
            <button type="button" class="btn btn-success" (click)="addUrlVideo()">Add</button>
            <button type="button" class="btn btn-default" (click)="isEditing = false">Cancel</button>
        </div>
    </div>
</div>
<div *ngIf="!isEditing">
    <div *ngIf="urlVideos.length">
        <h4>Videos</h4>
        <div class="row">
            <div *ngFor="let url of urlVideos; let i = index" class="col-xs-4 miles-inline-list">
                <span class="fa fa-fw fa-video-camera"></span>
                <span [ngClass]="{'primaryAttachment': primaryIndex === i && primaryIsUrl && !overridePrimary }">{{ url }}</span>
                <span class="fa fa-fw fa-trash text-danger delete" (click)="removeUrlVideo(i)"></span>
                <span class="fa fa-fw fa-star delete" (click)="toggleIndexAsPrimary(i, true)" [title]="getPrimaryButtonTooltip(i, true)"></span>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-flat" (click)="isEditing = true">Add videos</button>
</div>
