<div>
    <span class="issue-title">
        <span class="issue-number">{{ issue.iid }}</span> {{ issue.title }}
    </span>
    <span class="pull-right label" [ngClass]="isOpen ? 'label-primary' : 'label-default'">{{ isOpen ? 'Open' : 'Closed' }}</span>
</div>
<div class="issue-dates text-muted">
    updated <app-date-text [date]="issue.updated_at"></app-date-text> -- created
    <app-date-text [date]="issue.created_at"></app-date-text>
</div>
