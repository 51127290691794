import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IGlobalSearchItem } from '@common/global-search/global-search.library';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppError, NotFoundError } from '@mt-ng2/errors-module';

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchService {
    constructor(private httpClient: HttpClient) {}

    search(query: string): Observable<IGlobalSearchItem[]> {
        let params = new HttpParams();
        params = params.append('query', query);
        return this.httpClient
            .get<IGlobalSearchItem[]>('/global-search', {
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    handleError<T>(error: Response, formObject?: Observable<T>): Observable<never> {
        if (error.status === 400) {
            return throwError(() => error);
        }

        if (error.status === 404) {
            return throwError(() => new NotFoundError());
        }

        return throwError(() => new AppError(error, formObject));
    }
}
