import { IMetaItem } from '@mt-ng2/base-service';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'tags-view-item',
    styleUrls: ['./tags-view-item.component.less'],
    templateUrl: './tags-view-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsViewItemComponent implements OnChanges {
    @Input() tag: IMetaItem;
    @Input() prefix = '';
    @Input() labelType: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'secondary' = 'secondary';
    @Input() tagType: 'default' | 'package'

    isLink = false;
    labelClass = 'label label-default'
    link = ''

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tagType) {
            this.isLink = this.checkIsLink();
        }

        if (changes.tagType || changes.tag) {
            this.link = this.getLink(this.tag.Id);
        }        

        if (changes.labelType) {
            this.labelClass = this.getLabelClass();
        }        
              
    }   
    
    checkIsLink() {  
        return this.tagType == 'package';
     }

    getLabelClass(): string {
        return `label label-${this.labelType}`;
    }

    getLink(id: number): string {
        if (this.tagType == 'package') {
            return `/packages/${id}`;
        }
        return '';
    }
}
