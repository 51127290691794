import { Component, Output, EventEmitter, Input, inject, OnDestroy } from '@angular/core';

import { QuestionAttachmentsHelper } from './attachments.library';
import { IAttachmentWithAltText } from '@common/services/attachments.service';
import { QuestionAttachmentsService } from '../../questions/services/question-attachments.service';
import { Subscription } from 'rxjs';
import { FileItem } from 'ng2-file-upload';

@Component({
    selector: 'app-new-entity-attachments',
    styleUrls: ['../common.styles.less'],
    templateUrl: './new-entity-attachments.component.html',
})
export class NewEntityAttachmentsComponent implements OnDestroy {

    showUploadControl = false;
    @Input() headerTitle = 'Upload Attachments';
    @Input() uploadType = 'Attachments';
    @Input() btnMessage = 'Add attachments';
    allowedMimeTypes = QuestionAttachmentsHelper.allowedMimeTypes;
    primaryIndex = -1;

    @Input() fileItems: (FileItem | File | IAttachmentWithAltText)[] = [];
    @Input() showMarkAsPrimary = false;
    @Input() promptForAltText = false;
    @Input() overridePrimary = false;
    @Output() fileItemsUpdated = new EventEmitter<(FileItem | File | IAttachmentWithAltText)[]>();
    @Output() primaryIndexUpdated = new EventEmitter<number>();
    questionAttachmentsService = inject(QuestionAttachmentsService);
    subscriptions = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onAttachmentUploaded(fileItem: FileItem): void {
        if (QuestionAttachmentsHelper.isImage(fileItem.file.name) && this.promptForAltText) {
            this.subscriptions.add(this.questionAttachmentsService.confirmAttachment().subscribe((altText) => {
                if (altText) {
                    this.fileItems.push({ file: fileItem, altText });
                    this.fileItemsUpdated.emit(this.fileItems);
                }
            }));
        } else {
            this.fileItems.push(fileItem);
            this.fileItemsUpdated.emit(this.fileItems);
        }
        this.showUploadControl = false;
    }

    removeAttachment(index: number): void {
        this.fileItems.splice(index, 1);
        this.fileItemsUpdated.emit(this.fileItems);
    }

    togglePrimary(index: number): void {
        if (this.primaryIndex === index && !this.overridePrimary) {
            this.primaryIndex = -1;
        } else {
            this.primaryIndex = index;
        }
        this.primaryIndexUpdated.emit(this.primaryIndex);
    }

    isAttachmentWithAltText(object: any): object is IAttachmentWithAltText {
        return 'altText' in object;
    }

    getFilename(file: FileItem | File | IAttachmentWithAltText): string {
        if (this.isAttachmentWithAltText(file)) {
            return this.getFilename(file.file);
        } else if (file instanceof FileItem) {
            return file.file.name;
        } else {
            return file.name;
        }
    }
}
