import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IShowcase } from '@model/core/interfaces/showcase';
import { ShowcaseTypes } from '@model/core/enums/showcase-types.enum';
import { IShowcasePartial } from '@model/core/partials/showcase.partial';
import { Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InlineContentServiceFactory } from '../../common/services/inline-content.service';
import { InlineContentBaseService } from '../../common/services/inline-content-base.service';
import { UpdateFavoriteFunctions } from '@common/update-favorite/update-favorite.library';
import { IRecentActivitySearchResultDto } from '@model/core/interfaces/custom/recent-activity-search-result.dto';
import { IMetaItem } from '@model/core/interfaces/base';

const emptyShowcase: IShowcasePartial = {
    AssociatedShowcases: [],
    CreatedById: 0,
    Customer: null,
    DateCreated: new Date(),
    DateModified: new Date(),
    DetailDescription: null,
    Hidden: null,
    Id: 0,
    MilesPackages: [],
    PointBudget: null,
    PointOfContact: null,
    ProjectRepoUrl: null,
    ProjectTeam: null,
    ShowcaseTypeId: ShowcaseTypes.CodeSample,
    Tags: [],
    Title: null,
    TopicId: null,
    Version: null,
};

const emailRegex =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Injectable({
    providedIn: 'root',
})
export class ShowcaseService extends InlineContentBaseService<IShowcasePartial> {
    constructor(public http: HttpClient, inlineContentServiceFactory: InlineContentServiceFactory) {
        super('/showcases', http, inlineContentServiceFactory, 'DetailDescription');
    }

    getEmptyShowcase(): IShowcase {
        return JSON.parse(JSON.stringify(emptyShowcase));
    }

    emailRegex(): RegExp {
        return emailRegex;
    }

    getFeaturedShowcase(): Observable<IShowcase> {
        return this.http.get<IShowcase>('/showcases/featured');
    }

    getShowcaseAsMetaItem(): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>('/showcases/metaItems').pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    toggleFavorite(showcase: IShowcasePartial): Observable<number> {
        return this.http.post<number>(`/showcases/${showcase.Id}/toggle-favorite`, {}).pipe(
            tap(() => {
                UpdateFavoriteFunctions.updateFavorite<IShowcasePartial>(showcase);
            }),
        );
    }

    getFavoriteShowcases(): Observable<IRecentActivitySearchResultDto[]> {
        return this.http
            .get<IRecentActivitySearchResultDto[]>('/showcases/favorites')
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }
}
