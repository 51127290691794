<div ngbDropdown class="nav navbar-nav nav-header-button nav-notifications" [autoClose]="true" #navDropdown="ngbDropdown">
    <div ngbDropdownToggle class="dropdown-toggle hidden-xs hidden-sm" (click)="onNotificationClicked()">
        <ng-container *ngTemplateOutlet="badge"></ng-container>
    </div>
    <div class="hidden-md hidden-lg" (click)="seeAll()">
        <ng-container *ngTemplateOutlet="badge"></ng-container>
    </div>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <mt-notifications-list (unreadNotificationsCount)="unreadCount = $event"></mt-notifications-list>
    </div>
</div>

<ng-template #badge>
    <span class="help-text">
        <i class="fa fa-bell" aria-hidden="true"></i>
        <span class="badge" *ngIf="unreadCount > 0">
            {{ unreadCount }}
        </span>
    </span>
</ng-template>
