<mt-dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <!-- input element -->
    <textarea
        #inputElement
        cdkTextareaAutosize
        (focus)="onFocus()"
        (blur)="onBlur()"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name"
        class="form-control comment-text-area"
        [maxlength]="getMaxLength()"
        [attr.autocomplete]="!config.autoCompleteEnabled ? 'new-password' : null"
    ></textarea>
    <!-- maxlength elements -->
    <span
        *ngIf="!config.labelLeft && hasMaxLength()"
        [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
        class="small pull-right"
        [class.errortext]="hasError('maxlength')"
        [class.inside-box-maxlength]="config.insideBoxValidation"
        >{{ getMaxLengthText() }}</span
    >
    <span
        *ngIf="config.labelLeft"
        [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
        class="small pull-right"
        [class.errortext]="hasError('maxlength')"
        [class.inside-box-maxlength]="config.insideBoxValidation"
        >{{ getMaxLengthText() }}</span
    >
    <!-- validation elements -->
    <div *ngIf="config.validators">
        <div
            *ngIf="!showRequired() && hasError('required')"
            class="small errortext"
            [style.position]="'absolute'"
            [class.inside-box-validation]="config.insideBoxValidation"
        >
            {{ config.label }} is required
        </div>
        <div
            *ngIf="hasError('minlength')"
            class="small errortext"
            [style.position]="'absolute'"
            [class.inside-box-validation]="config.insideBoxValidation"
        >
            {{ config.label }} must be at least {{ config.validators.minlength }} characters
        </div>
    </div>
</mt-dynamic-form-input-wrapper>
