import { PipeTransform, Pipe } from '@angular/core';

import { common } from '@mt-ng2/common-functions';

@Pipe({ name: 'pluralizeIf' })
export class PluralizeIfPipe implements PipeTransform {
    transform(value: string, shouldPluralize: boolean): string {
        if (!shouldPluralize || !value) {
            return value;
        }
        return common.pluralize(value);
    }
}
