<div class="question-item" [class.with-top-border]="showTopBorder">
    <div class="team-div" *ngIf="!showDetail && showTeam">
        {{ showcase.ProjectTeam }}
    </div>
    <div class="question-details">
        <h5>
            <span class="title">{{ showcase.Title }}</span>
            <span class="relative-date">
                <by-user-on-date [user]="showcase.CreatedBy" [date]="showcase.DateCreated"></by-user-on-date>
            </span>
        </h5>
        <div class="type-tags-container">
            <span class="label label-warning">{{ showcase.ShowcaseType.Name }}</span>
            <span class="label label-default" *ngIf="showcase.Topic">Topic: {{ showcase.Topic.Name }}</span>
            <tags-view [tags]="showcase.MilesPackages" prefix="Package: " labelType="default"></tags-view>
            <tags-view [tags]="showcase.Tags"></tags-view>
        </div>
        <div *ngIf="showDetail">
            <div>
                <span><label>Team: </label> {{ showcase.ProjectTeam }}</span>
                <span class="margin-left"><label>Contact: </label> {{ showcase.PointOfContact }}</span>
            </div>
            <div *ngIf="(showcase.Customer || showcase.PointBudget)">
                <span *ngIf="showcase.Customer"><label>Customer: </label> {{ showcase.Customer }}</span>
                <span [ngClass]="{ 'margin-left': showcase.Customer }" *ngIf="showcase.PointBudget"
                    ><label>Points: </label> {{ showcase.PointBudget }}</span
                >
            </div>
            <div class="has-checks">
                <span>images [ <i class="fa" [ngClass]="showcase.HasImages ? 'fa-check text-success' : 'fa-fw'"></i> ]</span>
                <span>videos [ <i class="fa" [ngClass]="showcase.HasVideos ? 'fa-check text-success' : 'fa-fw'"></i> ]</span>
                <span *ngIf="showcase.Hidden">hidden [ <i class="fa fa-check text-success"></i> ]</span>
            </div>
        </div>
    </div>
</div>
