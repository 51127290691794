import { Component, Input } from '@angular/core';

import { IUser } from '@model/core/interfaces/user';

@Component({
    selector: 'by-user-on-date',
    styleUrls: ['./by-user-on-date.component.less'],
    templateUrl: './by-user-on-date.component.html',
})
export class ByUserOnDateComponent {
    @Input() user: IUser;
    @Input() date: Date;
    @Input() isAiGenerated = false;
}
