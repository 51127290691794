import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { SortDirection } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { QuestionAttachmentsHelper } from './attachments.library';
import { IModalOptions, ModalService } from '@mt-ng2/modal-module';
import { IAttachment } from '@model/core/interfaces/custom/attachment';
import { IAttachmentsService } from '@common/services/attachments.service';
import { IVersionable } from '@model/core/interfaces/base';
import { Subscription } from 'rxjs';
import { FileItem } from 'ng2-file-upload';

interface IVersionableAttachment extends IVersionable, IAttachment {}
@Component({
    selector: 'app-entity-attachments',
    styleUrls: ['../../questions/questions.less', '../common.styles.less'],
    templateUrl: './entity-attachments.component.html',
})
export class EntityAttachmentsComponent<TAttachment extends IVersionableAttachment> implements OnInit, OnDestroy {
    public isEditing = false;
    @Input() canEdit = false;
    @Input() canAdd = false;
    private _entityId: number;
    @Input() primaryId = -1;
    @Input() overridePrimary = false;
    @Input() headerTitle = 'Upload Attachments';
    @Input() uploadType = 'Attachments';
    @Input() btnMessage = 'Add attachments';

    @Input() attachmentsService: IAttachmentsService<TAttachment>;
    @Input() entityName: string;
    @Input() showMarkAsPrimary = false;
    attachmentsArray: TAttachment[] = [];
    allowedMimeTypes = QuestionAttachmentsHelper.allowedMimeTypes;

    @Input() entityVersion: number[];

    @Output() primaryIdUpdated = new EventEmitter<number>();
    @Output() versionUpdated = new EventEmitter<number[]>();
    subscriptions = new Subscription();
    @Input()
    set entityId(val: number) {
        this._entityId = val;
        this.getAttachments();
    }
    get entityId(): number {
        return this._entityId;
    }

    constructor(private notificationsService: NotificationsService, private modalService: ModalService, private cdr: ChangeDetectorRef) {}
    ngOnInit(): void {
        this.subscriptions.add(
            this.attachmentsService.screenshotAdded.subscribe((event) => {
                if (event.entityId === this.entityId) {
                    this.attachmentsArray.unshift(event.attachment);
                    this.cdr.detectChanges();
                }
            }),
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get showUploadArea(): boolean {
        return this.isEditing && this.canEdit;
    }

    get showEditButton(): boolean {
        return this.canEdit;
    }

    getAttachments(): void {
        const searchParamProperties: IEntitySearchParams = {
            order: 'DateUploaded',
            orderDirection: SortDirection.Desc,
            query: '',
        };
        const searchParams = new SearchParams(searchParamProperties);

        this.attachmentsService.getAttachments(this.entityId, searchParams).subscribe((response) => {
            this.attachmentsArray = response.body;
            this.cdr.detectChanges(); // parent edit component is using OnPush strategy, which means this is required
        });
    }

    onWhenAddingFileFailed(message: string): void {
        this.notificationsService.error(message);
    }

    addAttachment(file: FileItem): void {
        this.isEditing = false;
        this.saveAttachment(file);
    }

    saveAttachment(file: FileItem): void {
        if (file) {
            this.attachmentsService.uploadAttachment(this.entityId, file._file, false).subscribe((x: TAttachment) => {
                this.attachmentsArray.unshift(x);
                this.cdr.detectChanges();
                if (x.Version) {
                    this.entityVersion = x.Version;
                    this.versionUpdated.emit(this.entityVersion);
                }
            });
        }
    }

    showModal(index: number): void {
        this.attachmentsService.getAttachment(this.entityId, this.attachmentsArray[index].Id).subscribe((x) => {
            const fileAsBlob = new Blob([x], {
                type: 'application/octet-stream',
            });
            const options: IModalOptions = {
                heightAuto: true,
                imageUrl: URL.createObjectURL(fileAsBlob),
                showConfirmButton: false,
                width: 1024,
            };
            this.modalService.showModal(options).subscribe();
        });
    }

    cancel(): void {
        this.isEditing = false;
    }

    deleteAttachment(index: number): void {
        this.attachmentsService.deleteAttachment(this.entityId, this.attachmentsArray[index].Id).subscribe((result) => {
            this.attachmentsArray.splice(index, 1);
            this.primaryIdUpdated.emit(null);
            this.versionUpdated.emit(result as number[]);
            this.cdr.detectChanges();
        });
    }

    downloadAttachment(index: number): void {
        this.attachmentsService.getAttachment(this.entityId, this.attachmentsArray[index].Id).subscribe((x) => {
            const fileAsBlob = new Blob([x], {
                type: 'application/octet-stream',
            });
            saveAs(fileAsBlob, this.attachmentsArray[index].Filename);
        });
    }

    isImage(filename: string): boolean {
        return QuestionAttachmentsHelper.isImage(filename);
    }

    getAttachmentApiPath(attachment: TAttachment): string {
        return `/${this.entityName}/${this.entityId}/attachments/${attachment.Id}/image`;
    }

    togglePrimary(id: number): void {
        if (this.primaryId === id && !this.overridePrimary) {
            this.primaryId = -1;
        } else {
            this.primaryId = id;
        }
        this.primaryIdUpdated.emit(this.primaryId);
    }
}
