import { Component, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, HostListener } from '@angular/core';
import { IGlobalSearchItem } from './global-search.library';
import { QuestionTypes } from '@model/core/enums/question-types';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./global-search-results-window.component.less'],
    templateUrl: './global-search-results-window.component.html',
})
export class GlobalSearchResultsWindowComponent {
    @HostBinding('class') hostClass = 'dropdown-menu show';
    @HostBinding('role') hostRole = 'listbox';

    private _items: IGlobalSearchItem[];

    set items(value: IGlobalSearchItem[]) {
        this._items = value;
        this.cdr.detectChanges();
    }
    get items(): IGlobalSearchItem[] {
        return this._items;
    }

    get questionTypes(): typeof QuestionTypes {
        return QuestionTypes;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    @HostListener('mousedown', ['$event']) onHostMouseDown(event: MouseEvent): void {
        event.preventDefault();
    }

    isShowcase(item: IGlobalSearchItem): boolean {
        return item.Title === 'Showcase';
    }

    isReleaseNote(item: IGlobalSearchItem): boolean {
        return item.Title === 'Release Note';
    }

    isCodeTip(item: IGlobalSearchItem): boolean {
        return item.Title === 'Code Tip';
    }
}
