import { CustomNotificationsHeaderComponent } from './home/custom-notifications-header/custom-notifications-header.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { LoginConfigOverride } from '@common/configs/login.config';

import { ToastrModule } from 'ngx-toastr';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './home/menu.component';
import { MenuWindowComponent } from './home/menu-window.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieModule } from '@mt-ng2/cookie';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        NotFoundComponent,
        HomeComponent,
        MenuComponent,
        MenuWindowComponent,
        LoginComponent,
        CustomNotificationsHeaderComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        SharedModule,
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule,
        AuthModule.forRoot(),
        MtDisableDuringHttpCallsModule.forRoot(),
        AppRoutingModule,
        NotificationsModule,
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
