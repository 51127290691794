<div class="header">
    <a [routerLink]="['/']" class="logo pointer">
        <div class="logo-link">
            <img class="logo visible-md-block visible-lg-block" [src]="logo" height="50" alt="logo" />
            <img class="logo visible-xs-block visible-sm-block" [src]="logoSmall" height="50" alt="logo" />
        </div>
    </a>
    <nav class="custom-navbar" role="navigation">
        <ul class="navbar">
            <li class="global-search">
                <global-search></global-search>
            </li>
            <li class="dropdown">
                <div class="dropbtn"><i class="fa fa-fw fa-2x fa-user-circle-o"></i></div>
                <ul class="dropdown-content dropdown-menu">
                    <li (click)="goToDashboard()">
                        <a><i class="fa fa-fw fa-user-o"></i> My Recent Activities</a>
                    </li>
                    <li (click)="goToPairProgramming()">
                        <a><i class="fa fa-fw fa-desktop"></i> Pair Programming</a>
                    </li>
                    <li (click)="goToSettings()">
                        <a><i class="fa fa-fw fa-gear"></i> Settings</a>
                    </li>
                    <li (click)="logout()">
                        <a><i class="fa fa-fw fa-power-off"></i> Logout</a>
                    </li>
                </ul>
            </li>
            <li class="packages">
                <a routerLink="/packages" class="active" href="#"><i class="fa fa-fw fa-gift"></i> Packages</a>
            </li>
            <li class="notifications">
                <custom-header-notifications></custom-header-notifications>
            </li>
            <li class="menu">
                <custom-menu></custom-menu>
            </li>
        </ul>
    </nav>
</div>
<router-outlet> </router-outlet>
