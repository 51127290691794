import { Component, ComponentRef, Injector, NgZone, Renderer2, ViewContainerRef, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PopupService, positionElements } from '@mt-ng2/type-ahead-control';
import { GlobalSearchResultsWindowComponent } from './global-search-results-window.component';
import { IGlobalSearchItem } from './global-search.library';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';
import { GlobalSearchService } from '@common/services/global-search.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'global-search',
    styleUrls: ['./global-search.component.less'],
    templateUrl: './global-search.component.html',
})
export class GlobalSearchComponent implements OnDestroy {
    /**
     * service used to open the results window component below
     */
    popupService: PopupService<GlobalSearchResultsWindowComponent>;
    /**
     * the component ref for the results window once it is opened
     */
    windowRef: ComponentRef<GlobalSearchResultsWindowComponent>;

    subscriptions = new Subscription();
    searchSubscription: Subscription = null;
    searchBarApi: ISearchbarControlAPI;
    /**
     * Returns true if the results window is displayed
     */
    get isResultsWindowOpen(): boolean {
        return this.windowRef != null;
    }

    private router: Router;

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, ngZone: NgZone, private globalSearchService: GlobalSearchService) {
        this.popupService = new PopupService<GlobalSearchResultsWindowComponent>(GlobalSearchResultsWindowComponent, injector, vcr, renderer);
        this.router = injector.get(Router);

        this.subscriptions.add(
            ngZone.onStable.subscribe(() => {
                // this fires whenever Angular is done running a set of tasks
                // and so long as the results window is open when Angular is done running tasks
                // we want to position/reposition the results window to be attached to the input element
                if (this.isResultsWindowOpen) {
                    const inputElement = this.searchBarApi.getSearchInputElement() as ElementRef<HTMLElement>;
                    positionElements(inputElement.nativeElement, this.windowRef.location.nativeElement as HTMLElement, 'bottom-left', true);
                    renderer.setStyle(this.windowRef.location.nativeElement, 'z-index', 1090); // can set this to a higher number if needed
                    // match the results window width to the same as the input elements width
                    renderer.setStyle(this.windowRef.location.nativeElement, 'width', `${inputElement.nativeElement.offsetWidth}px`);
                }
            }),
        );
        this.subscriptions.add(
            this.router.events.subscribe((evt) => {
                if (evt instanceof NavigationEnd) {
                    this.closeResultsWindow();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onSearchBarReady(event: ISearchbarControlAPI): void {
        this.searchBarApi = event;
    }

    onSearch(value: string): void {
        if (!value) {
            this.closeResultsWindow();
        } else {
            if (this.searchSubscription) {
                this.searchSubscription.unsubscribe();
            }
            this.searchSubscription = this.globalSearchService.search(value).subscribe((items) => {
                if (this.isResultsWindowOpen) {
                    // if already open, just set the new array of items
                    this.windowRef.instance.items = items;
                } else {
                    // else open the window with the items
                    this.openResultsWindow(items);
                }
            });
        }
    }

    openResultsWindow(items: IGlobalSearchItem[]): void {
        if (this.isResultsWindowOpen) {
            return;
        }
        // open the results window
        this.windowRef = this.popupService.open();
        // set the items to show in the results window
        this.windowRef.instance.items = items;
        // add the results window to the dom
        window.document.querySelector('body').appendChild(this.windowRef.location.nativeElement);
    }

    closeResultsWindow(): void {
        if (!this.isResultsWindowOpen) {
            return;
        }
        this.popupService.close();
        this.windowRef = null;
    }
}
