import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'secureResourceUrl',
})
export class SecureResourceUrlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, prefix = ''): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(prefix + (value as string));
  }

}
