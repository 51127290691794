import { Component, Input } from '@angular/core';
import { IShowcasePartial } from '@model/core/partials/showcase.partial';

@Component({
    selector: 'app-showcase-list-item',
    styleUrls: ['../../questions/questions.less', '../question-list-item/question-list-item.component.less', './showcase-list-item.component.less'],
    templateUrl: './showcase-list-item.component.html',
})
export class ShowcaseListItemComponent {
    @Input() showcase: IShowcasePartial;
    @Input() showDetail = true;
    @Input() showTeam = true;
    @Input() showTopBorder = true;
}
