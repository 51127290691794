import { Component, Input } from '@angular/core';
import { GitlabIssueState } from '@model/core/enums/gitlab-issue-state';
import { IGitlabIssue } from '@model/core/interfaces/custom/gitlab-issue';

@Component({
    selector: 'gitlab-issue-list-item',
    styleUrls: ['./gitlab-issue-list-item.component.less'],
    templateUrl: './gitlab-issue-list-item.component.html',
})
export class GitlabIssueListItemComponent {
    @Input() issue: IGitlabIssue;
    get isOpen(): boolean {
        return this.issue.state === GitlabIssueState.Open;
    }
}
