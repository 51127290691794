import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'relativeDateText' })
export class RelativeDateTextPipe implements PipeTransform {
    transform(value: string | Date): string {
        if (!value) {
            return null;
        }
        const dateValue = new Date(value);
        return dateValue.mtDate.from(new Date());
    }
}
