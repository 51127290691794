<div class="group-wrapper">
    <ul class="list-group">
        <a class="disable-link-look" *ngFor="let item of items" [routerLink]="item.RouteTo">
            <li class="list-group-item" >
                <strong class="title label" [ngClass]="{
                    'question-result': item.QuestionTypeId === questionTypes.Question,
                    'consult-result': item.QuestionTypeId === questionTypes.Consultation,
                    'feature-request-result': item.QuestionTypeId === questionTypes.FeatureRequest,
                    'report-result': item.QuestionTypeId === questionTypes.Feedback,
                    'showcase-result': isShowcase(item),
                    'release-note-result': isReleaseNote(item),
                    'code-tip-result': isCodeTip(item)}"
                >
                    {{ item.Title }}
                </strong>
                {{ item.Description }}
            </li>
        </a>

    </ul>
</div>
