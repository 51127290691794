<div class="code-tip-item" [class.with-top-border]="showTopBorder">
    <div class="code-tip-title" *ngIf="!showDetail">
        Code Tip
    </div>
    <div class="code-tip-details">
        <h5>
            <span class="title">{{ codeTip.Title }}</span
            ><span class="relative-date">
                <by-user-on-date [user]="codeTip.CreatedBy" [date]="codeTip.DateCreated"></by-user-on-date>
            </span>
        </h5>
        <div class="type-tags-container">
            <span class="label label-primary">Code Tip</span>
            <span class="label label-default" *ngIf="codeTip.Topic">Topic: {{ codeTip.Topic.Name }}</span>
            <tags-view [tags]="codeTip.MilesPackages" prefix="Package: " labelType="default"></tags-view>
            <tags-view [tags]="codeTip.Tags"></tags-view>
        </div>
        <div class="description-container" *ngIf="showDetail">
            <mt-wysiwyg-view [value]="codeTip.DetailDescription"></mt-wysiwyg-view>
        </div>
    </div>
</div>
