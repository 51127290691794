import { HttpClient } from '@angular/common/http';
import { BaseService, ICreateOptions, IEntity } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { IInlineContentApi, InlineContentService, InlineContentServiceFactory } from './inline-content.service';

export abstract class InlineContentBaseService<T extends IEntity> extends BaseService<T> implements IInlineContentApi {
    private readonly inlineContentService: InlineContentService;

    constructor(
        private readonly baseUrl: string, http: HttpClient,
        inlineContentServiceFactory: InlineContentServiceFactory,
        private readonly wysiwygFieldName: keyof T & string,
    ) {
        super(baseUrl, http);
        this.inlineContentService = inlineContentServiceFactory.getInstance(this);
    }

    uploadInlineContentFile(entityId: number, inlineId: string, file: File): Observable<void> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<void>(`${this.baseUrl}/${entityId}/inline-file/${inlineId}`, formData).pipe(
            catchError((err, caught) => this.handleError(err as Response, caught)),
        );
    }

    updateInlineContentSource(entityId: number): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${entityId}/apply-inline-files`, {}).pipe(
            catchError((err, caught) => this.handleError(err as Response, caught)),
        );
    }

    createWithFks(object: T): Observable<number> {
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.createWithFks({
            ...object,
            [this.wysiwygFieldName]: html,
        }));
    }

    create(object: T, options?: ICreateOptions): Observable<number> {
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.create({
            ...object,
            [this.wysiwygFieldName]: html,
        }, options));
    }

    update(object: T): Observable<object> {
        let result: object;
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.update({
            ...object,
            [this.wysiwygFieldName]: html,
        }).pipe(
            tap((x) => { result = x; }),
            map(() => object.Id),
        )).pipe(
            map(() => result),
        );
    }

    updateWithFks(object: T): Observable<object> {
        let result: object;
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.updateWithFks({
            ...object,
            [this.wysiwygFieldName]: html,
        }).pipe(
            tap((x) => { result = x; }),
            map(() => object.Id),
        )).pipe(
            map(() => result),
        );
    }

    updateVersion(object: T): Observable<number[]> {
        let result: number[];
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.updateVersion({
            ...object,
            [this.wysiwygFieldName]: html,
        }).pipe(
            tap((x) => { result = x; }),
            map(() => object.Id),
        )).pipe(
            map(() => result),
        );
    }

    updateVersionWithFks(object: T): Observable<number[]> {
        let result: number[];
        return this.inlineContentService.setInlineContent(object[this.wysiwygFieldName] as unknown as string, (html) => super.updateVersionWithFks({
            ...object,
            [this.wysiwygFieldName]: html,
        }).pipe(
            tap((x) => { result = x; }),
            map(() => object.Id),
        )).pipe(
            map(() => result),
        );
    }

    updatePartial(object: object, id: number): Observable<object> {
        if (!Object.keys(object).includes(this.wysiwygFieldName)) {
            return super.updatePartial(object, id);
        }
        let result: object;
        return this.inlineContentService.setInlineContent((object as Record<string, string>)[this.wysiwygFieldName], (html) => super.updatePartial({
            ...object,
            [this.wysiwygFieldName]: html,
        }, id).pipe(
            tap((x) => { result = x; }),
            map(() => id),
        )).pipe(
            map(() => result),
        );
    }

    updatePartialVersionable(object: object, id: number): Observable<object> {
        if (!Object.keys(object).includes(this.wysiwygFieldName)) {
            return super.updatePartial(object, id);
        }
        let result: object;
        return this.inlineContentService.setInlineContent((object as Record<string, string>)[this.wysiwygFieldName], (html) => super.updatePartialVersionable({
            ...object,
            [this.wysiwygFieldName]: html,
        }, id).pipe(
            tap((x) => { result = x; }),
            map(() => id),
        )).pipe(
            map(() => result),
        );
    }
}
