import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const linkRegex = /https:\/\/drive\.google\.com\/file\/d\/[^/]+/

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-google-drive-video-embed',
    templateUrl: './google-drive-video-embed.component.html',
})
export class GoogleDriveVideoEmbedComponent {
    private _embedLink: SafeResourceUrl;
    get embedLink(): SafeResourceUrl {
        return this._embedLink;
    }

    private _link: string;
    @Input() set link(value: string) {
        if (this._link !== value) {
            this._link = value;
            const match = value?.match(linkRegex)?.[0];
            this._embedLink = match ? this.sanitizer.bypassSecurityTrustResourceUrl(`${match}/preview`) : null;
            this.cdr.markForCheck();
        }
    }
    get link(): string {
        return this._link;
    }

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}
}
