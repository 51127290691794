import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from '@mt-ng2/auth-module';

import { ICodeTipPartial } from '@model/core/partials/codetip.partial';

@Component({
    selector: 'app-code-tip-list-item',
    styleUrls: ['../../questions/questions.less', './code-tip-list-item.component.less'],
    templateUrl: './code-tip-list-item.component.html',
})
export class CodeTipListItemComponent implements OnInit {
    @Input() codeTip: ICodeTipPartial;
    @Input() showDetail;
    @Input() showTopBorder = true;

    currentUserId: number;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }
}
