// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    actionableNotificationUrl: '/breck-ext/actionable-notifications',
    apiVersion: '1',
    appName: 'Rendezvous',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    authSecretVariable: 'verysecret',
    baseApiUrl: '',
    codeDocsPath: 'http://rendezvous-docs.testing.milesapp.com',
    connectionEndpointFullUrl: null,
    docPath: 'docs/',
    gitlabBaseUrl: 'https://gitlab.4miles.com',
    googleApiKey: '240960442735-j41i8mncsphjii1ii3npijted1ub50av',
    imgPath: 'docs/images/',
    logger: false,
    notificationSubscriptionPublicKey: 'BHlOwJ6d8vmtHFtBJmIxs80nYSScbaX0k3azCqPKooQ1U4UA7bhLhRNzhywma8MPnB0U56-H47OFEGX0PT3mYiY',
    playgroundPath: 'http://package-name.testing.milesapp.com',
    production: false,
    rtcApiUrl: '',
    siteName: 'rendezvous',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
    useRtc: true,
};
