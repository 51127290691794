import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, HostBinding, HostListener } from '@angular/core';

export enum MenuActionTypes {
    Route = 1,
    Logout = 2,
}

export interface IMenuAction {
    type: MenuActionTypes;
    routeTo?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./menu-window.component.less'],
    templateUrl: './menu-window.component.html',
})
export class MenuWindowComponent {
    @HostBinding('class') hostClass = 'dropdown-menu show';
    @HostBinding('role') hostRole = 'listbox';

    @Input() canSeeUsers = false;
    @Input() canSeeAdmin = false;
    @Input() menuElementId: string;

    @Output() outsideClick = new EventEmitter<void>();
    @Output() selection = new EventEmitter<IMenuAction>();

    @HostListener('mousedown', ['$event']) onHostMouseDown(event: MouseEvent): void {
        event.preventDefault();
    }

    @HostListener('document:click', ['$event.target.id', '$event.target.parentElement.id'])
    onDocumentClick(targetId: string, parentId: string): void {
        if (this.menuElementId && targetId !== this.menuElementId && parentId !== this.menuElementId) {
            this.outsideClick.emit();
        }
    }

    routeTo(routeTo: string): void {
        this.selection.emit({ type: MenuActionTypes.Route, routeTo: routeTo });
    }

    logout(): void {
        this.selection.emit({ type: MenuActionTypes.Logout });
    }
}
