import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class GoogleCalendarAuthService {

    baseControllerPath = '/google-calendar-auth';
    constructor(public http: HttpClient) { }

    getIsCalendarPermissionGranted(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseControllerPath}/granted`);
    }

    getRedirect(): Observable<string> {
        return this.http.get<string>(`${this.baseControllerPath}/get-auth`)
    }

    saveToken(token: string): Observable<string> {
        return this.http.get<string>(`${this.baseControllerPath}/create-token?token=${token}`)
    }
}
