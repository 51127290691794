import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem, FileUploader } from 'ng2-file-upload';

export interface IPrimaryVideoChangedEvent {
    index: number;
    isUrlVideo: boolean;
}

export interface IVideoUploadWrapperForm {
    UrlVideo: FormGroup<IVideoUploadForm>;
}

export interface IVideoUploadForm {
    UrlVideo?: FormControl<string>;
}

@Component({
    selector: 'app-video-upload',
    styleUrls: ['../common.styles.less'],
    templateUrl: './video-upload.component.html',
})
export class VideoUploadComponent implements OnInit {
    urlVideoField: DynamicField;
    form: FormGroup<IVideoUploadWrapperForm>;
    uploader: FileUploader;
    @Input() urlVideos: string[] = [];
    files: FileItem[] = [];
    isEditing = false;
    @Input() primaryIndex = -1;
    primaryIsUrl = true;

    @Input() overridePrimary = false;
    @Output() primaryVideoChanged = new EventEmitter<number>();
    @Output() urlVideoAdded = new EventEmitter<string>();
    @Output() urlVideoRemoved = new EventEmitter<number>();
    @Output() videoUploadsChanged = new EventEmitter<FileItem[]>();

    constructor(private fb: FormBuilder, private notificationService: NotificationsService) {}

    ngOnInit(): void {
        this.initUrlVideoField();
        this.initUploader();
    }

    initUrlVideoField(): void {
        this.form = this.fb.group({
            UrlVideo: this.fb.group({}),
        });

        const youtubeOrGoogleDriveRegex =
            /(^(?:https:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)(.*)$)|(^(?:https:\/\/)?drive.google.com\/file\/d\/(.*)\/view\?usp=sharing$)/;
        this.urlVideoField = new DynamicField({
            formGroup: 'UrlVideo',
            label: 'YouTube or Google Drive URL',
            name: 'UrlVideo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            }),
            validation: [Validators.pattern(youtubeOrGoogleDriveRegex)],
            validators: {
                pattern: youtubeOrGoogleDriveRegex,
            },
            value: null,
        });
        this.urlVideoField.setRequired(true);
    }

    initUploader(): void {
        this.uploader = new FileUploader({
            allowedMimeType: ['video/mp4', 'video/webm', 'video/ogg'],
            isHTML5: true,
            url: '',
        });
        this.uploader.onAfterAddingFile = (item) => this.afterAddingFile(item);
    }

    addUrlVideo(): void {
        if (this.form.valid) {
            const newVideo = this.form.value.UrlVideo.UrlVideo;
            this.urlVideos.push(newVideo);
            this.urlVideoAdded.emit(newVideo);
            this.isEditing = false;
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationService.error('Please enter a valid Google Drive or YouTube URL.', 'Invalid URL');
        }
    }

    afterAddingFile(file: FileItem): void {
        this.files.push(file);
        this.videoUploadsChanged.emit(this.files);
        this.isEditing = false;
    }

    removeVideo(index: number): void {
        this.files.splice(index, 1);
        this.videoUploadsChanged.emit(this.files);
    }

    removeUrlVideo(index: number): void {
        this.urlVideos.splice(index, 1);
        this.urlVideoRemoved.emit(index);
    }

    toggleIndexAsPrimary(index: number, isUrlVideo: boolean): void {
        if (this.primaryIndex === index && !this.overridePrimary) {
            this.primaryIndex = -1;
        } else {
            this.primaryIndex = index;
        }
        this.primaryIsUrl = isUrlVideo;
        this.primaryVideoChanged.emit(index);
    }

    getPrimaryButtonTooltip(index: number, isUrlVideo: boolean): string {
        if (this.primaryIndex === index && this.primaryIsUrl === isUrlVideo) {
            return 'Unmark as primary';
        }
        return 'Mark as primary';
    }
}
