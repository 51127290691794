import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, MtAuthGuard } from '@mt-ng2/auth-module';

import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig, LoginModuleConfigToken, ILoginConfig } from '@mt-ng2/login-module';
import { environment } from '../environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

export interface ILoginForm {
    username: FormControl<string>;
    password: FormControl<string>;
}

@Component({
    selector: 'app-login',
    styleUrls: ['./login.component.less'],
    template: `
        <div class="login-page">
            <div class="login-header">
                <div class="login-header-line">
                    <span>G</span><span>R</span><span>E</span><span>E</span><span>T</span><span>I</span><span>N</span><span>G</span><span>S</span
                    ><span>&nbsp;</span><span>P</span><span>R</span><span>O</span><span>F</span><span>E</span><span>S</span><span>S</span
                    ><span>O</span><span>R</span>
                </div>
                <div class="login-header-line">
                    <span>S</span><span>H</span><span>A</span><span>L</span><span>L</span><span>&nbsp;</span><span>W</span><span>E</span
                    ><span>&nbsp;</span><span>P</span><span>L</span><span>A</span><span>Y</span><span>&nbsp;</span><span>A</span><span>&nbsp;</span
                    ><span>G</span><span>A</span><span>M</span><span>E</span><span>?</span>
                </div>
            </div>
            <div class="login-box">
                <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
                <div class="login-box-body">
                    <div class="text-center">
                        <div *ngIf="hasGoogleAuthConfig">
                            <br />
                            <mt-google-login [config]="config"></mt-google-login>
                        </div>
                    </div>
                </div>
                <form *ngIf="loginForm && !isProduction" [formGroup]="loginForm" (submit)="onLogin()">
                    <div class="form-group has-feedback" [class.has-error]="showUsernameRequiredError()">
                        <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                        <span class="fa fa-user form-control-feedback"></span>
                        <div *ngIf="showUsernameRequiredError()" class="small errortext" [style.position]="'absolute'">Username is required</div>
                    </div>
                    <div class="form-group has-feedback" [class.has-error]="showPasswordRequiredError()">
                        <input #Password type="password" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                        <span class="fa fa-lock form-control-feedback"></span>
                        <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'absolute'">Password is required</div>
                    </div>
                    <div class="padded block-parent">
                        <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    `,
})
export class LoginComponent implements OnInit {
    logoFull = `${environment.assetsPath}logo-full.png`;
    isProduction = environment.production;
    returnUrl = '';
    loginForm: FormGroup<ILoginForm>;
    public config: LoginConfig;
    get hasGoogleAuthConfig(): boolean {
        return this.config.googleAuthConfig ? true : false;
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        @Inject(LoginModuleConfigToken) private loginConfig: ILoginConfig,
        private pageTitlesService: PageTitlesService,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.config = new LoginConfig(this.loginConfig);
        this.setPageTitle();
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
        this.createForm();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Login');
    }

    createForm(): void {
        if (!this.loginForm) {
            this.loginForm = new FormGroup<ILoginForm>({
                password: new FormControl(''),
                username: new FormControl(''),
            });
        }
    }
    showUsernameRequiredError(): boolean {
        const control = this.loginForm.get('username');
        return control.touched && control.hasError('required');
    }

    showPasswordRequiredError(): boolean {
        const control = this.loginForm.get('password');
        return control.touched && control.hasError('required');
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value;
            this.authService.login(values.username, values.password, false).subscribe(() => {
                void this.router.navigate(['/home']);
            });
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }
}
