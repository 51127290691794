import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/core/interfaces/user';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IMtMentionsUserService } from '@mt-ng2/mentions';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> implements IMtMentionsUserService {
    private _adminUsers: IUser[];
    private _users: IUser[];
    private _pairProgrammerUsers: IUser[];

    constructor(public http: HttpClient) {
        super('/users', http);
    }

    updateAdminAccess(userId: number, hasAccess: boolean): Observable<void> {
        return this.http.put<void>(`/users/${userId}/adminAccess`, { HasAccess: hasAccess });
    }

    /**
     * This will be replaced with an API call
     * @returns Observable<IUser[]>
     */
    getAdminUsers(): Observable<IUser[]> {
        if (!this._adminUsers) {
            return this.http.get<IUser[]>('/users/admin').pipe(tap((users) => (this._adminUsers = users)));
        } else {
            return of(this._adminUsers);
        }
    }

    getMentionUsers(): Observable<IUser[]> {
        if (!this._users) {
            return this.http.get<IUser[]>('/users/users-for-mentions').pipe(tap((users) => (this._users = users)));
        } else {
            return of(this._users);
        }
    }

    getPairProgrammerUsers(): Observable<IUser[]> {
        if (!this._pairProgrammerUsers) {
            return this.http.get<IUser[]>('/users/pair-programmers').pipe(tap((users) => (this._pairProgrammerUsers = users)));
        } else {
            return of(this._pairProgrammerUsers);
        }
    }

}
