import { Component, ElementRef, Output, ViewChild, EventEmitter, forwardRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RvSearchBarComponent),
        },
    ],
    selector: 'rv-search-bar',
    styleUrls: ['./search-bar.component.less'],
    templateUrl: './search-bar.component.html',
})
export class RvSearchBarComponent extends MtSearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('searchInput', { static: true }) inputElement: ElementRef;
    @Output() inputBlur: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.ready.emit({
            clearSearch: this.clearSearch.bind(this),
            getSearchControl: (() => this.searchControl).bind(this),
            getSearchInputElement: (() => this.inputElement).bind(this),
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
