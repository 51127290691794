import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@mt-ng2/auth-module';
import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { PathNotFoundResolveService } from './default-routes/path-not-found-resolve.service';
import { appPaths } from './default-routes/app-paths.library';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AllNotificationsComponent } from '@mt-ng2/actionable-notifications-module';

const appRoutes: Routes = [
    { component: LoginComponent, data: { pageClass: 'login' }, path: appPaths.login, title: 'Login' },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'landing-page',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: 'packages', loadChildren: () => import('./packages/packages.module').then((m) => m.PackagesModule) },
            { path: 'release-notes', loadChildren: () => import('./release-notes/release-notes.module').then((m) => m.ReleaseNotesModule) },
            { path: 'questions', loadChildren: () => import('./questions/question.module').then((m) => m.QuestionModule) },
            { path: 'guides', loadChildren: () => import('./upgrade-guides/upgrade-guides.module').then((m) => m.UpgradeGuidesModule) },
            { path: 'users', loadChildren: () => import('./users/user.module').then((m) => m.UserModule) },
            { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
            { path: 'landing-page', loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule) },
            { path: 'kpi', loadChildren: () => import('./kpi/kpi.module').then((m) => m.KpiModule) },
            { path: 'notification-settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
            { path: 'showcases', loadChildren: () => import('./showcases/showcases.module').then((m) => m.ShowcasesModule) },
            { path: 'all-notifications', component: AllNotificationsComponent },
            { path: 'my-dashboard', loadChildren: () => import('./my-activities/my-activities.module').then((m) => m.MyActivitiesModule) },
            {
                path: 'pair-programming-sessions',
                loadChildren: () =>
                    import('./pair-programming-sessions/pair-programming-sessions.module').then((m) => m.PairProgrammingSessionsModule),
            },
        ],
        component: HomeComponent,
        path: appPaths.home,
        title: 'Rendezvous',
    },
    {
        path: 'home',
        pathMatch: 'full',
        redirectTo: 'landing-page',
    },
    {
        canActivate: [AuthGuard],
        component: NotFoundComponent,
        path: '**',
        resolve: {
            path: PathNotFoundResolveService,
        },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', initialNavigation: 'disabled' })],
})
export class AppRoutingModule {}
