import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from '../environments/environment';

@Component({
    styleUrls: ['./home.component.less'],
    templateUrl: './home.component.html',
})
export class HomeComponent {
    logo = `${environment.assetsPath}logo.png`;
    logoSmall = `${environment.assetsPath}logo-small.png`;
    constructor(
        private authService: AuthService,
        private router: Router
    ) {    }

    logout(): void {
        this.authService.logout();
    }

    goToDashboard(): void {
        void this.router.navigateByUrl("/my-dashboard");
    }

    goToPairProgramming(): void {
        void this.router.navigateByUrl("/pair-programming-sessions");
    }

    goToSettings(): void {
        void this.router.navigateByUrl('/notification-settings');
    }
}
