import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-date-text',
    templateUrl: './date-text.component.html',
})
export class DateTextComponent {
    @Input() date: string | Date;
}
