import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IUserVisit } from '@model/core/interfaces/user-visit';
import { BaseService } from '@mt-ng2/base-service';

@Injectable({ providedIn: 'root' })
export class UserVisitsService extends BaseService<IUserVisit> {
    constructor(private httpClient: HttpClient) {
        super('/user-visits', httpClient);
    }
}
