export enum ClaimTypes {
    Users = 2,
    MilesPackages = 7,
    PackageVersions = 8,
    UpgradeGuides = 9,
    ReleaseNotes = 10,
    Questions = 11,
    QuestionAnswers = 12,
    QuestionAnswerThreads = 13,
    Tags = 14,
    Admin = 15,
    Notifications = 16,
    Showcases = 17,
    PairProgrammer = 18,
}
