import { IMetaItem } from '@mt-ng2/base-service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'tags-view',
    styleUrls: ['./tags-view.component.less'],
    templateUrl: './tags-view.component.html',
})
export class TagsViewComponent {
    @Input() tags: IMetaItem[] = [];
    @Input() prefix = '';
    @Input() labelType: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'secondary' = 'secondary';
    @Input() tagType: 'default' | 'package'


    getLabelClass(): string {
        return `label label-${this.labelType}`;
    }

    isLink(): boolean {
        return this.tagType == 'package';
    }

    getLink(id: number): string {
        if (this.tagType == 'package') {
            return `/packages/${id}`;
        }
        return '';
    }
}
