import { IMetaItem } from '@mt-ng2/base-service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TagService } from '@common/services/tags.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { ITag } from '@model/core/interfaces/tag';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { ILabelService } from '@common/services/label.service';

@Component({
    selector: 'tags-edit',
    styleUrls: ['./tags-edit.component.less'],
    templateUrl: './tags-edit.component.html',
})
export class TagsEditComponent<T extends IMetaItem> implements OnInit {
    private _selectedTags: IMetaItem[] = [];
    @Output() selectedTagsChange = new EventEmitter<IMetaItem[]>(); // handles Angular multi direction binding

    @ViewChild('filter', { static: false }) filter: ElementRef;

    @Input() labelService: ILabelService<T>;
    @Input() entity = 'tags';

    filterText: string;
    labels: IMetaItem[];
    tagsAsMultiselectItem: MultiselectItem[];

    canAddNewTag = false;
    @Input() set selectedTags(value: IMetaItem[]) {
        this._selectedTags = value;
        if (this.labels) {
            this.setTagsAsMultiselectItem();
        }
    }
    get selectedTags(): IMetaItem[] {
        return this._selectedTags;
    }
    get filteredTags(): MultiselectItem[] {
        if (this.filterText && this.tagsAsMultiselectItem) {
            return this.tagsAsMultiselectItem.filter((item) => (item.Item.Name ).toLowerCase().includes(this.filterText.toLowerCase()));
        } else {
            return this.tagsAsMultiselectItem;
        }
    }

    get filterTextCanBeAdded(): boolean {
        return this.entity === 'tags' && this.filterText && !this.labels.some((tag) => tag.Name.toLowerCase() === this.filterText.toLowerCase()) ? true : false;
    }

    constructor(private tagsService: TagService, private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.labelService.getAll().subscribe((labels) => {
            this.labels = labels;
            this.setTagsAsMultiselectItem();
        });
        this.canAddNewTag = this.claimsService.hasClaim(ClaimTypes.Tags, [ClaimValues.FullAccess]);
    }

    private setTagsAsMultiselectItem(): void {
        this.tagsAsMultiselectItem = this.labels.map((tag) => {
            return new MultiselectItem(
                tag,
                this.selectedTags.some((item) => item.Id === tag.Id),
            );
        });
    }

    openChange(open: boolean): void {
        if (open) {
            setTimeout(() => {
                this.filter?.nativeElement?.focus?.();
            }, 0);
        } else {
            this.filterText = '';
        }
    }

    itemSelected(item: MultiselectItem): void {
        item.Selected = !item.Selected;
        if (item.Selected) {
            this._selectedTags.push(item.Item as IMetaItem);
        } else {
            const index = this._selectedTags.findIndex((tag) => tag.Id === item.Item.Id);
            this._selectedTags.splice(index, 1);
        }
        this.selectionChanged();
    }

    removeAll(): void {
        this.selectedTags = [];
        this.setTagsAsMultiselectItem();
        this.selectionChanged();
    }

    selectionChanged(): void {
        this.selectedTagsChange.emit(this.selectedTags);
    }

    addNewTag(): void {
        const newTag: ITag = { Id: 0, Name: this.filterText };
        this.tagsService.create(newTag).subscribe((id) => {
            newTag.Id = id;
            this.labels.push(newTag);
            this.setTagsAsMultiselectItem();
        });
    }
}
