import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ByUserDateModule } from "./byuserdate.module";
import { CodeTipListItemComponent } from "./code-tip-list-item/code-tip-list-item.component";
import { QuestionListItemComponent } from "./question-list-item/question-list-item.component";
import { ShowcaseListItemComponent } from "./showcase-list-item/showcase-list-item.component";
import { TagsViewComponent } from "./tags/view/tags-view.component";
import { WysiwygModule } from '@mt-ng2/wysiwyg-control';
import { TagsViewItemComponent } from "./tags/view/tags-view-item.component";
import { RouterModule } from "@angular/router";
import { QuillModule } from 'ngx-quill';

@NgModule({
    declarations: [
        QuestionListItemComponent,
        ShowcaseListItemComponent,
        CodeTipListItemComponent,
        TagsViewComponent,
        TagsViewItemComponent
    ],
    exports: [
        WysiwygModule,
        QuestionListItemComponent,
        ShowcaseListItemComponent,
        CodeTipListItemComponent,
        TagsViewComponent,
        TagsViewItemComponent
    ],
    imports: [
        WysiwygModule,
        QuillModule,        
        ByUserDateModule,
        CommonModule,
        RouterModule
    ],
    providers: [],
})
export class ListItemsModule {}