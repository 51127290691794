<div class="question-item" [class.with-top-border]="showTopBorder">
    <div class="question-stats" [ngClass]="{ answered: question.QuestionStatusId === answeredStatus }">
        <ng-container *ngIf="question.QuestionTypeId === questionTypes.Question">
            <ng-container *ngIf="question.AnswerCount > 0 || question.CommentCount === 0; else comments">
                <div class="stats-count">{{ question.AnswerCount }}</div>
                <div class="stats-label">{{ 'answer' | pluralizeIf: question.AnswerCount !== 1 }}</div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="question.QuestionTypeId !== questionTypes.Question">
            <ng-container *ngTemplateOutlet="comments"></ng-container>
        </ng-container>
    </div>
    <div class="question-details">
        <div *ngIf="showStatusLabel" class="status-label">
            <span class="label" [ngClass]="isOpen ? 'label-primary' : 'label-default'">{{ question.QuestionStatus.Name }}</span>
        </div>
        <h5>
            <span class="title">{{ question.Title }}</span
            ><span class="relative-date">
                <by-user-on-date [user]="question.CreatedBy" [date]="question.DateCreated"></by-user-on-date>
            </span>
        </h5>
        <div class="type-tags-container">
            <span class="label label-primary">{{ question.QuestionType.Name }}</span>
            <span class="label label-default" *ngIf="question.Topic">Topic: {{ question.Topic.Name }}</span>
            <span class="label label-default" *ngIf="question.MilesPackage">Package: {{ question.MilesPackage.Name }}</span>
            <tags-view [tags]="question.Tags"></tags-view>
        </div>
        <div class="description-container" *ngIf="showDetail">
            <!-- <mt-wysiwyg-view [value]="question.DetailedDescription"></mt-wysiwyg-view> -->
             <quill-view-html [content]="question.DetailedDescription" [sanitize]="true" theme="snow"></quill-view-html>
        </div>
    </div>
</div>
<ng-template #comments>
    <div class="stats-count">{{ question.CommentCount }}</div>
    <div class="stats-label">{{ 'comment' | pluralizeIf: question.CommentCount !== 1 }}</div>
</ng-template>
