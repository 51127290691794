import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from '@mt-ng2/auth-module';

import { IQuestionPartial } from '@model/core/partials/question-partial';
import { QuestionStatuses } from '@model/core/enums/question-statuses';
import { QuestionTypes } from '@model/core/enums/question-types';

@Component({
    selector: 'app-question-list-item',
    styleUrls: ['../../questions/questions.less', './question-list-item.component.less'],
    templateUrl: './question-list-item.component.html',
})
export class QuestionListItemComponent implements OnInit {
    @Input() question: IQuestionPartial;
    @Input() showDetail = true;
    @Input() showTopBorder = true;

    currentUserId: number;
    questionTypes = QuestionTypes;
    answeredStatus = QuestionStatuses.Answered;

    get isOpen(): boolean {
        return this.question.QuestionStatusId === QuestionStatuses.InProgress || this.question.QuestionStatusId === QuestionStatuses.NotTriaged;
    }

    get showStatusLabel(): boolean {
        return this.question.QuestionStatusId === QuestionStatuses.Closed || this.question.QuestionStatusId === QuestionStatuses.Inactive;
    }

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }
}
