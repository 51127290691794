import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { IUser } from '@model/core/interfaces/user';

@Pipe({ name: 'userName' })
export class UserNamePipe implements PipeTransform {
    currentUserId: number;

    constructor(private authService: AuthService) {
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }

    transform(value: IUser): string {
        if (value == null) {
            return '';
        }
        return value.Id === this.currentUserId ? 'You' : `${value.FirstName} ${value.LastName}`;
    }
}
