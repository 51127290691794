<div *ngIf="showUploadControl">
    <mt-document [headerTitle]="headerTitle" [allowedMimeType]="allowedMimeTypes" (afterAddingFile)="onAttachmentUploaded($event)"></mt-document>
    <br />
</div>
<div *ngIf="!showUploadControl">
    <div *ngIf="fileItems.length">
        <h4>{{ uploadType }}</h4>
        <div class="row">
            <div *ngFor="let file of fileItems; let i = index" class="col-xs-4 miles-inline-list">
                <span class="fa fa-fw {{ getFilename(file) | fileicon }}"></span>
                <span [ngClass]="{'primaryAttachment': primaryIndex === i && !overridePrimary}">{{ getFilename(file) }}</span>
                <span class="fa fa-fw fa-trash text-danger delete" (click)="removeAttachment(i)"></span>
                <span class="fa fa-fw fa-star delete" (click)="togglePrimary(i)" *ngIf="showMarkAsPrimary"></span>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-flat" (click)="showUploadControl = true">{{ btnMessage }}</button>
</div>
