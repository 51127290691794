export interface IIsFavoritable {
    IsFavorited?: boolean;
    FavoriteCount?: number;
}

export class UpdateFavoriteFunctions {

    static updateFavorite<T extends IIsFavoritable>(favoriteEntity: T) {
        favoriteEntity.IsFavorited = !favoriteEntity.IsFavorited;
        if(favoriteEntity.FavoriteCount != undefined) {
            if (favoriteEntity.IsFavorited) {
                favoriteEntity.FavoriteCount += 1;
            } else {
                favoriteEntity.FavoriteCount -= 1;
            }
        }
    }
}
