<div class="group-wrapper">
    <ul class="list-group">
        <li (click)="routeTo('/questions')" class="list-group-item">
            <a><i class="fa fa-fw fa-question"></i> Questions</a>
        </li>
        <li (click)="routeTo('/showcases')" class="list-group-item">
            <a><i class="fa fa-fw fa-cubes"></i> Showcases</a>
        </li>
        <li (click)="routeTo('/packages')" class="list-group-item">
            <a><i class="fa fa-fw fa-gift"></i> Packages</a>
        </li>
        <li (click)="routeTo('/guides')" class="list-group-item">
            <a><i class="fa fa-fw fa-wrench"></i> Upgrade Guides</a>
        </li>
        <li (click)="routeTo('/release-notes')" class="list-group-item">
            <a><i class="fa fa-fw fa-book"></i> Release Notes</a>
        </li>
        <li *ngIf="canSeeUsers" (click)="routeTo('/questions/triage')" class="list-group-item">
            <a><i class="fa fa-fw fa-hospital-o"></i> Triage</a>
        </li>
        <li *ngIf="canSeeUsers" (click)="routeTo('/users')" class="list-group-item">
            <a><i class="fa fa-fw fa-users"></i> Users</a>
        </li>
        <li *ngIf="canSeeAdmin" (click)="routeTo('/kpi')" class="list-group-item">
            <a><i class="fa fa-fw fa-flag-checkered"></i> Kpis</a>
        </li>
        <li (click)="routeTo('/about')" class="list-group-item">
            <a><i class="fa fa-fw fa-info"></i> About</a>
        </li>
        <li (click)="logout()" class="list-group-item">
            <a><i class="fa fa-fw fa-power-off"></i> Logout</a>
        </li>
    </ul>
</div>
