import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAttachment } from '@model/core/interfaces/custom/attachment';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, tap, concatMap } from 'rxjs/operators';
import { FileItem } from 'ng2-file-upload';

export interface IAttachmentsService<TAttachment extends IAttachment> {
    screenshotAdded: Observable<IScreenshotAddedEvent<TAttachment>>; // observable that emits the entity id that has had a screenshot added
    getAttachment(entityId: number, attachmentId: number): Observable<Blob>;
    getAttachments(entityId: number, searchParameters: SearchParams): Observable<HttpResponse<TAttachment[]>>;
    uploadAttachment(entityId: number, file: File, isPrimary: boolean): Observable<TAttachment>;
    uploadAttachments(entityId: number, attachments: (FileItem | File)[], primaryIndex: number): Observable<TAttachment[]>;
    deleteAttachment(entityId: number, attachmentId: number): Observable<object>;
}

export interface IScreenshotAddedEvent<TAttachment extends IAttachment> {
    attachment: TAttachment;
    entityId: number;
}

export interface IAttachmentWithAltText {
    file: File | FileItem;
    altText: string;
}

export abstract class AttachmentUtilities {
    public static getBlobFromDataUrl(imageUrl: string): Observable<Blob | null> {
        return from(fetch(imageUrl)).pipe(
            concatMap((response) => response.blob()),
            catchError((error) => {
                // This will happen when trying to fetch an image from a different domain
                console.error('Failed to fetch image:', error);
                return of(null);
            }),
        );
    }
}

export class AttachmentsService<T extends IAttachment> extends BaseService<T> implements IAttachmentsService<T> {
    screenshotAdded: Observable<IScreenshotAddedEvent<T>>;
    private screenshotAddedSubject: Subject<IScreenshotAddedEvent<T>>;

    constructor(protected baseUrl: string, protected entity: string, public http: HttpClient) {
        super(baseUrl, http);
        this.screenshotAddedSubject = new Subject<IScreenshotAddedEvent<T>>();
        this.screenshotAdded = this.screenshotAddedSubject.asObservable();
    }

    getAttachment(entityId: number, attachmentId: number): Observable<Blob> {
        return this.http
            .get(`${this.baseUrl}/${entityId}/${this.entity}/${attachmentId}`, {
                responseType: 'blob' as const,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getAttachments(entityId: number, searchParameters: SearchParams): Observable<HttpResponse<T[]>> {
        const params = this.getHttpParams(searchParameters);
        return this.http
            .get<T[]>(`${this.baseUrl}/${entityId}/${this.entity}/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    uploadAttachment(entityId: number, file: File, isPrimary = false, emitEvent = false, isNew = false, altText = ''): Observable<T> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const queryParams: Record<string, string> = {};
        if (isPrimary) {
            queryParams['isPrimary'] = 'true';
        }
        if (altText !== '') {
            queryParams['altText'] = btoa(altText);
        }
        if (isNew) {
            queryParams['isNew'] = 'true';
        }
        return this.http.post<T>(`${this.baseUrl}/${entityId}/${this.entity}`, formData, { params: queryParams }).pipe(
            catchError((err, caught) => this.handleError(err as Response, caught)),
            tap((attachment) => {
                if (emitEvent) {
                    this.screenshotAddedSubject.next({
                        attachment: attachment,
                        entityId: entityId,
                    });
                }
            }),
        );
    }

    deleteAttachment(entityId: number, attachmentId: number): Observable<object> {
        return this.http
            .delete(`${this.baseUrl}/${entityId}/${this.entity}/${attachmentId}`)
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    uploadAttachments(entityId: number, attachments: (FileItem | File)[], primaryIndex = -1, isNew = true): Observable<T[]> {
        const uploads = attachments.map((item, index) => {
            const file = item instanceof FileItem ? item._file : item;
            return this.uploadAttachment(entityId, file, index === primaryIndex, false, isNew);
        });
        return forkJoin(uploads);
    }

    uploadAttachmentsWithAltText(entityId: number, attachments: IAttachmentWithAltText[], primaryIndex = -1, isNew = true): Observable<T[]> {
        const uploads = attachments.map((item, index) => {
            const file = item.file instanceof FileItem ? item.file._file : item.file;
            return this.uploadAttachment(entityId, file, index === primaryIndex, false, isNew, item.altText);
        });
        return forkJoin(uploads);
    }
}
