import { Component, Input, Injector, ViewContainerRef, Renderer2, NgZone, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { TypeAheadComponent } from '@mt-ng2/type-ahead-control';
import { Observable, of } from 'rxjs';
import { ShowcaseService } from '../../showcases/services/showcase.service';
import { IMetaItem } from '@mt-ng2/base-service';

export interface IShowcaseTypeAheadAPI {
    addFilterItem: (id: number) => void;
    removeFilterItem: (id: number) => void;
}
@Component({
    selector: 'app-showcase-virtual-typeahead',
    template: `
        <ng-container>
            <span>
                <input
                    #inputElement
                    type="text"
                    class="form-control"
                    [placeholder]="placeholder"
                    autocorrect="off"
                    autocomplete="off"
                    autocapitalize="off"
                    role="combobox"
                    [formControl]="inputControl"
                    (blur)="onBlur()"
                    (keydown)="handleKeyDown($event)"
                />
            </span>
        </ng-container>
    `,
})
export class ShowcaseVirtualTypeAheadComponent extends TypeAheadComponent implements OnInit, OnDestroy {
    showcases: IMetaItem[] = [];
    @Input() filteredShowcases: number[] = [];
    @Input() onlyEmitChanges = false;

    placeholder = 'Search by Showcase Name or Enter Showcase Url';
    minimumCharactersToShow = 0;

    @Input() selectedValue: number;
    @Input() currentShowcaseId = 0;
    @Output() showcaseReady = new EventEmitter<IShowcaseTypeAheadAPI>();

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, ngZone: NgZone, showcaseService: ShowcaseService) {
        super(injector, vcr, renderer, ngZone);

        if (this.minimumCharactersToShow === undefined) {
            this.minimumCharactersToShow = 2;
        }

        this.subscriptions.add(
            this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
                if (value && value.trim().length >= this.minimumCharactersToShow) {
                    if (!this.isPopupOpen() && value && !(this.selected && this.getValue(this.selected) === value)) {
                        this.openPopup();
                    } else if (this._windowRef) {
                        this.getShowcasesForTypeAhead(value).subscribe((items) => {
                            this._windowRef.instance.items = items;
                            this._windowRef.instance.searchText = value;
                            this._windowRef.instance.cdr.detectChanges();
                        });
                    }
                } else if (this.isPopupOpen()) {
                    this.closePopup();
                }
            }),
        );
        showcaseService.getShowcaseAsMetaItem().subscribe((showcasesResult) => {
            this.showcases = showcasesResult.filter((x) => x.Id !== this.currentShowcaseId);
            if (this.selectedValue > 0 && !this.onlyEmitChanges) {
                const showcase = this.showcases.filter((showcase) => showcase.Id === this.selectedValue).pop();
                this.selectedItem = showcase;
            }
        });
    }

    ngOnInit(): void {
        this.showcaseReady.emit({
            addFilterItem: this.addFilterItem.bind(this),
            removeFilterItem: this.removeFilterItem.bind(this),
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    protected openPopup(): void {
        if (this.isPopupOpen()) {
            return;
        }
        this.subscriptions.add(
            this.getShowcasesForTypeAhead(this.inputControl.value as string).subscribe((items) => {
                this._windowRef = this._popupService.open();
                this._windowRef.instance.items = items;
                this._windowRef.instance.searchText = this.inputControl.value;
                this._windowRef.instance.nameProperty = this.nameProperty;
                this._windowRef.instance.maxToShow = this.maxToShow;
                this.subscriptions.add(
                    this._windowRef.instance.onSelection.subscribe((result) => {
                        this.handleSelection(result);
                        this.closePopup();
                    }),
                );
                window.document.querySelector('body').appendChild(this._windowRef.location.nativeElement);
            }),
        );
    }

    getShowcasesForTypeAhead(text: string): Observable<IMetaItem[]> {
        const showcaseUrlRegex = /^.*#\/showcases\/([0-9]+)[/]?$/;
        if (showcaseUrlRegex.test(text)) {
            const matches = text.match(showcaseUrlRegex);
            const showcaseId = parseInt(matches[1], 10);
            const showcase = this.showcases.filter((showcase) => showcase.Id === showcaseId && !this.filteredShowcases.includes(showcaseId)).pop();
            if (showcase) {
                if (!this.onlyEmitChanges) {
                    this.selectedItem = showcase;
                } else {
                    this.inputControl.patchValue('');
                }
                this.selectionChanged.emit({ selection: showcase });
                return of([showcase]);
            }
        }
        return of(
            this.showcases.filter(
                (showcase) => showcase.Name.toLocaleLowerCase().includes(text.toLocaleLowerCase()) && !this.filteredShowcases.includes(showcase.Id),
            ),
        );
    }

    addFilterItem(id: number): void {
        if (!this.filteredShowcases.includes(id)) {
            this.filteredShowcases.push(id);
        }
    }

    removeFilterItem(id: number): void {
        const index = this.filteredShowcases.indexOf(id, 0);
        if (index > -1) {
            this.filteredShowcases.splice(index, 1);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSelection(selected: any): void {
        if (!this.onlyEmitChanges) {
            this.selected = selected;
            if (selected) {
                this.inputControl.patchValue(this.getValue(selected));
            } else {
                this.inputControl.patchValue('');
            }
        } else {
            this.inputControl.patchValue('');
        }
        this.selectionChanged.emit({ selection: selected });
    }
}
