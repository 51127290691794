import { DynamicField, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { IDynamicFormModuleConfig } from '@mt-ng2/dynamic-form';

import { ExpandableTextAreaComponent } from '@common/expandable-text-area/expandable-text-area.component';
import { CommonService } from '@common/services/common.service';

export function customFormComponentFunction(dynamicField: DynamicField): typeof ExpandableTextAreaComponent {
    if (dynamicField.type.fieldType === DynamicFieldTypes.Input && dynamicField.type.inputType === InputTypes.Textarea) {
        return ExpandableTextAreaComponent;
    }
    return null; // fall through to the standard form control selection logic
}

export const dynamicFormModuleConfig: IDynamicFormModuleConfig = {
    commonService: CommonService,
    customFormComponentFunction: customFormComponentFunction,
};
