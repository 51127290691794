import { NotificationPopupService } from './../notification-popup.service';
import { Component, Injector } from '@angular/core';
import { NavHeaderNotificationsComponent } from '@mt-ng2/actionable-notifications-module';

@Component({
    selector: 'custom-header-notifications',
    styleUrls: ['./custom-notifications-header.component.less'],
    templateUrl: './custom-notifications-header.component.html',
})
export class CustomNotificationsHeaderComponent extends NavHeaderNotificationsComponent {
    private notificationPopupService: NotificationPopupService;
    constructor(injector: Injector) {
        super(injector);
        this.notificationPopupService = injector.get(NotificationPopupService);
    }

    onNotificationClicked(): void {
        this.notificationPopupService.openNotificationPopup();
    }
}
