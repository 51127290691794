import { ILabelService } from './label.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ITag } from '@model/core/interfaces/tag';
import { BaseService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class TagService extends BaseService<ITag> implements ILabelService<ITag> {
    constructor(private httpClient: HttpClient) {
        super('/tags', httpClient);
    }

    getAll(): Observable<ITag[]> {
        return this.httpClient.get<ITag[]>('/options/tags').pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }
}
