import { IMetaItem } from '@model/core/interfaces/base';

export enum QuestionTypes {
    Question = 1,
    Consultation = 2,
    Feedback = 3,
    FeatureRequest = 5,
}

export abstract class QuestionTypesHelper {
    public static GetAsMetaItems(): IMetaItem[] {
        return [
            { Id: QuestionTypes.Question, Name: 'Question' },
            { Id: QuestionTypes.Consultation, Name: 'Consultation' },
            { Id: QuestionTypes.Feedback, Name: 'Report' },
            { Id: QuestionTypes.FeatureRequest, Name: 'Feature Request' },
        ];
    }
}
